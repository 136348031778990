import axios from 'axios'
const isProduction = process.env.NODE_ENV === "production"
// create an axios instance
// import { Toast } from 'vant';
const service = axios.create({
  // baseURL: 'https://cms.gg.com', // url = base url + request url
  // baseURL: 'http://10.10.3.91:5000',
  // baseURL: isProduction ? location.origin : process.env.VUE_APP_BASE_API,
  baseURL: isProduction ? location.origin : process.env.VUE_APP_BASE_API,
  timeout: 60000, // request timeout,
  headers: {
    'X-Real-Ip': window.uip
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    // if (res && res.code !== 200) {
    //   Toast.fail('网络错误!')
    //   return res
    // }
    return res

    // if the custom code is not 20000, it is judged as an error.
    // if (res.code !== 20000) {
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('err' + error) // for debug
    // Toast.fail({
    //   message: '网络错误!',
    //   duration: 2500
    // })
    return Promise.reject(error)
  }
)

export default service
