import request from '@/utils/request'

export const reqGetNewsList = (params) => {
  return request({
    url: '/openApi/article_new',
    method: 'get',
    params
  })
}
export const reqGetContent = (id) => {
  return request({
    url: `/openApi/article_new/${id}`,
    method: 'get'
  })
}

export const reqGetSetting = (params) => {
  return request({
    url: '/openApi/openSetting',
    method: 'get',
    params
  })
}
